/**
 * pre数据集
 * @type {({})[]}
 */
export const preTab = [
    {
        value:1,
        label:'Image',
    },
    {
        value:2,
        label:'Brands',
    },
    {
        value:3,
        label:'Categories',
    },
]

/**
 * pre头数据
 * @type {*[]}
 */
export const preColumns = [
    {
        title: 'Title',
        width:140,
        key: 'enTitle'
    },
    {
        title: 'Start',
        width:140,
        slot: 'startTime'
    },
    {
        title: 'Status',
        slot:'status',
    },
    {
        type: 'selection',
        width:60,
        align: 'center'
    },
    {
        title:' ',
        width:120,
        slot:'btn',
    }
]
