import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * get 获取Pre数据
 * @param params
 * @returns {Promise<*>}
 */
export const getPreListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/pre/get?cid1='+params.cid1+'&type='+params.type))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * delete 删除Pre数据
 * @param params
 * @returns {Promise<*>}
 */
export const delPreListService = async (params) => {
    try {
        const { data } = await request.delete(evnUrl.concat('/api/pre?ids='+params.ids+'&type='+params.type))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 获取Pre 详情 数据
 * @param params
 * @returns {Promise<*>}
 */
export const getPreInfoService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/pre/id?id='+params.id+'&type='+params.type))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 修改列表状态
 * @param params
 * @returns {Promise<*>}
 */
export const getPreEditStatusService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/pre/update/status?id='+params.id+'&status='+params.status+'&type='+params.type))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 编辑图文数据
 * @param params
 * @returns {Promise<*>}
 */
export const editPreImageTextService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/api/pre'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
